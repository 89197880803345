const API_URL = 'https://api.dcarvalho.com.br:36777/restjd';
// const API_URL = 'https://api-appdcarvalho.sibe.cloud';


let headers = new Headers({
  'Content-Type': 'application/json'
});



// /**
//  * Configura o cabeçalho de autenticação com Basic Auth
//  * @param {string} username - O nome de usuário para autenticação
//  * @param {string} password - A senha para autenticação
//  */
// export const setAuthHeader = (username, password) => {
//   const token = btoa(`${username}:${password}`);
//   headers.set('Authorization', `Basic ${token}`);
// };

/**
 * Configura o cabeçalho de autenticação com os parâmetros USR e PWD
 * @param {string} username - O nome de usuário para autenticação
 * @param {string} password - A senha para autenticação
 */
export const setAuthHeader = (accessToken) => {
  headers.set('Authorization', `Bearer ${accessToken}`);
  // headers.set('PWD', password);
};


/**
 * Faz uma requisição HTTP para a API
 * @param {string} endpoint - O endpoint da API
 * @param {string} method - O método HTTP (GET, POST, etc.)
 * @param {Object} options - As opções para a requisição fetch
 * @param {number} timeout - O tempo limite para a requisição
 * @returns {Promise<Response>} - O objeto Response
 * @throws {Error} - Lança um erro se a requisição falhar
 */
export async function fetchData(endpoint, method, options = {}, timeout = 5000) {
  const controller = new AbortController();
  const { signal } = controller;

  const fetchTimeout = setTimeout(() => {
    controller.abort();
  }, timeout);

  try {
    const response = await fetch(`${API_URL}${endpoint}`, { ...options, method, headers, signal });

    clearTimeout(fetchTimeout);

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(errorText);
    }

    return response;
  } catch (error) {
    if (error.name === 'AbortError') {
      throw new Error('Request timed out');
    }
    throw new Error(error.message);
  }
}
