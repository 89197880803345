import React from 'react';
import { useAuth } from '../../context/AuthContext';

const Footer = () => {
  // Pegar o estado do usuário do AuthContext
  const { user } = useAuth();

  // Obter dinamicamente userId e displayName
  const userId = user?.id || 'Usuário não encontrado';
  const displayName = user?.displayName || 'Nome não disponível';

  return (
    <footer className="bg-light">
      <div className="container themeTextColorPrimary fw-bold">
        <p className="m-0 p-0">D.CARVALHO | JOHN DEERE</p>
        <p className="m-0 p-0">{userId} - {displayName}</p>
      </div>
    </footer>
  );
};

export default Footer;
