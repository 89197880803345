import { fetchData, setAuthHeader } from '../utils/datasource';

const defaultUser = {
  username: 'paulo',
  name: 'PAULO PRECHT'
}

export const signIn = async (username, password) => {
  try {
    console.log('signIn()')
    // setAuthHeader(username, password)

    const response = await fetchData(`/api/oauth2/v1/token?grant_type=password&password=${password}&username=${username}`, 'POST');
    const loginData = await response.json()

    console.log(loginData)

    // Verifica se há um erro no login (exemplo: PASS_INVALID_TESTE)
    if (!loginData.access_token) {
      throw new Error(`Erro de autenticação: ${loginData.erro_access}`);
    }

    // localStorage.setItem('authCredentials', loginData);

    // Codifica usuário e senha em Base64
    const credentials = btoa(`${username}:${password}`);

    // Adiciona os dados no localStorage
    localStorage.setItem('authCredentials', JSON.stringify({
      ...loginData,
      credentials
    }));

    return {
      isOk: true,
      data: defaultUser
    };
  }
  catch (error) {
    console.error(error)
    return {
      isOk: false,
      message: error.message
    };
  }
}

export const getUser = async () => {
  try {
    console.log('getUser()');
    const credentials = JSON.parse(getAuthCredentials());
    console.log(credentials);

    if (credentials) {
      // Configurar o cabeçalho de autorização com o token
      setAuthHeader(credentials.access_token);

      // Decodificar o token JWT para obter o userId
      function parseJwt(token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
          atob(base64)
            .split('')
            .map((c) => {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
        );

        return JSON.parse(jsonPayload);
      }

      const tokenPayload = parseJwt(credentials.access_token);
      const userId = tokenPayload.userid; // Obter o campo userid do payload do token
      console.log('Extracted userId:', userId);

      // Buscar os dados dos usuários
      const response = await fetchData('/api/framework/v1/users', 'GET');
      const userData = await response.json();

      // Procurar o usuário com o userId extraído
      const user = userData.items.find((user) => user.id === userId);

      if (user) {
        console.log('User found:', user);
      } else {
        console.log('User not found');
      }

      return {
        isOk: true,
        data: user,
      };
    } else {
      console.log('No credentials found');
      return {
        isOk: false,
        message: 'No credentials found',
      };
    }
  } catch (error) {
    console.error('Error in getUser():', error);
    return {
      isOk: false,
    };
  }
};


export const checkSecurity = async () => {
  try {
    console.log('checkSecurity()');
    const credentials = JSON.parse(getAuthCredentials());
    console.log(credentials)
    if (credentials) {
      setAuthHeader(credentials.access_token);
      const data = await fetchData('/check_security', 'GET');
      return {
        isOk: true,
        data
      };
    } else {
      console.log('No credentials found')
      return {
        isOk: false,
        message: 'No credentials found'
      };
    }
  }
  catch {
    return {
      isOk: false
    };
  }
}

const getAuthCredentials = () => {
  const credentials = localStorage.getItem('authCredentials');
  if (credentials) {
    return credentials
  }
  return null;
};